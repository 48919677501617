import styled from "styled-components";
import { Link } from "react-scroll";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const LandingContainer = styled.section`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.9) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const LandingBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const WebBg = styled.div`
  background: #232a34;
  z-index: -1;
  display: block;
`;

export const MobileBg = styled.div`
  display: none;
  background: #232a34;
  z-index: -1;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ColorBg = styled.div`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.99) 0%, transparent 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LandingContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingH1 = styled.h1`
  color: #fff;
  font-size: 3rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const LandingP = styled.p`
  margin-top: -20px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 480px) {
    font-size: 1.125rem;
  }
`;

export const LandingBtnWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled(Link)`
  border-radius: 5px;
  background: #424971;
  white-space: nowrap;
  padding: 16px 22px 14px 22px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: #4b5381;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  vertical-align: sub;
  font-size: 1.25rem;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  vertical-align: sub;
  font-size: 1.25rem;
`;
